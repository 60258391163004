import { API_DOMAIN } from 'data/constant'
import axios from 'utils/axiosApi'

export async function searchAreaApi(query: string) {
  const response = await axios.request({
    url: `google_place_services/autofill?query=${query}`,
    method: 'get',
  })
  return response.data
}

// set up auto campaign
export const setMarketingUserInterest = ({
  rcid,
  ubid,
  note,
}: {
  rcid: string
  ubid: string
  note: string
}) => {
  return async () => {
    const response = await axios.request({
      url: `${API_DOMAIN}/client_restaurant/marketing/user_interest`,
      data: {
        rcid,
        ubid,
        note,
      },
      method: 'post',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}

// get up auto campaign
export const getMarketingDetail = ({
  rcid,
  ubid,
}: {
  rcid: string
  ubid: string
}) => {
  return async () => {
    const response = await axios.request({
      url: `${API_DOMAIN}/client_restaurant/marketing/get_detail`,
      params: {
        rcid,
        ubid,
      },
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}

import { LinedChatComment } from '@rushable/icons'
import cn from 'classnames'
import QRCode from 'qrcode.react'
import { useAppSelector } from 'redux/hooks'
import parseMediaAbsoluteURL from 'utils/parseMediaAbsoluteURL'

import type { TFlyerColorOptions } from '../helpers/constant'

type TMarketingRender = {
  color: TFlyerColorOptions
  title: string
  description: string
  couponDescription: string
  pCode: string
  storeHostname: string
  qrCodeUrl: string
  imgUrl: string
  storeLogo: string
  storeName: string
  storeBanner: string | null
  nowTime: number
  canDownload?: boolean
}

export default function MarketingRender({
  color,
  title,
  description,
  couponDescription,
  pCode,
  storeHostname,
  qrCodeUrl,
  imgUrl,
  storeLogo,
  storeName,
  storeBanner,
  nowTime,
  canDownload = false,
}: TMarketingRender): JSX.Element {
  const { selectedLocation } = useAppSelector(state => state.location)
  const logoPadding = selectedLocation?.brand?.logo_padding
  const appearance = selectedLocation?.brand?.appearance
  return (
    <>
      <div className='w-[1120px] h-[672px] relative overflow-hidden'>
        <div
          className='w-[1120px] h-[672px] bg-no-repeat bg-cover bg-center'
          style={{
            backgroundImage: 'linear-gradient(45deg, #C4C4C4, #C4C4C4)',
          }}
        >
          <>
            {imgUrl || storeBanner ? (
              <img
                className='w-full h-full object-cover object-center'
                src={`${parseMediaAbsoluteURL(
                  imgUrl ? imgUrl : storeBanner,
                )}?t=${nowTime}`}
                crossOrigin='anonymous'
                alt='marketing banner'
              />
            ) : null}
          </>
        </div>
        <div className='w-[1500px] h-[672px] bg-black absolute top-[578px] right-0 transform rotate-[6deg]' />
        <div className='h-[128px] absolute bottom-[20px] left-[120px]'>
          <div
            className='h-[128px] relative z-10'
            style={{
              backgroundColor: appearance === 'dark' ? '#161616' : '#ffffff',
              padding: logoPadding ? '12px' : '',
            }}
          >
            {storeLogo ? (
              <img
                className='h-full'
                src={`${parseMediaAbsoluteURL(storeLogo)}?t=${nowTime}`}
                crossOrigin='anonymous'
                alt='logo'
              />
            ) : (
              <div
                className={cn(
                  'py-0 px-8 h-full flex justify-center items-center font-bold text-5xl ',
                  appearance === 'dark' ? 'text-white' : 'text-black',
                )}
                style={
                  canDownload
                    ? {
                        position: 'relative',
                        bottom: '20px',
                      }
                    : {}
                }
              >
                {storeName}
              </div>
            )}
          </div>
          <div
            className='w-full h-[128px] absolute'
            style={{
              top: '11.2px',
              left: '11.2px',
              backgroundColor: color?.value,
            }}
          />
        </div>
      </div>
      <div className='px-[120px] py-28 pt-20 pb-12'>
        <div
          className='pb-6 text-3xl flex items-center'
          style={{ color: color?.value }}
        >
          <div
            className={cn(
              canDownload ? 'relative top-[18px]' : 'relative top-1.5',
            )}
          >
            <LinedChatComment size={30} className={color?.value} />
          </div>
          <span className='pl-4'>New Announcement</span>
        </div>
        <div
          className='text-5xl font-bold pb-10'
          style={{ color: color?.value }}
        >
          {title}
        </div>
        <div className='w-[150px] h-1 bg-white' />
      </div>
      <div className='h-[420px] relative'>
        <div className='px-28 pt-0 pb-16 text-3xl text-white leading-normal'>
          {description}
        </div>
        {pCode && (
          <div
            className={cn(
              'absolute bottom-0 left-0 px-28 pt-0 pb-8 text-[32px] font-bold text-white',
            )}
            style={{
              wordSpacing: canDownload ? '8px' : '',
            }}
          >
            <div>{couponDescription}</div>
            Use code: <span style={{ color: color?.value }}> {pCode}</span>
          </div>
        )}
      </div>
      <div
        className='ml-28 px-20 py-0 w-[896px] h-[224px] border-4 flex justify-between items-center'
        style={{ borderColor: color?.value }}
      >
        <div
          className={cn('w-[480px]', canDownload ? 'relative bottom-4' : '')}
        >
          <div className='text-3xl text-white leading-normal'>
            For your next order, please scan
          </div>
          <div className='text-3xl text-white leading-normal'>
            the QR code or visit our website
          </div>
          <div className='text-3xl text-white leading-normal'>
            at <span className='underline'>{storeHostname}</span>
          </div>
        </div>
        <div className='w-[160px] h-[160px] bg-white flex justify-center items-center'>
          <QRCode
            size={1000}
            style={{ width: 142, height: 142 }}
            value={`https://${qrCodeUrl}`}
          />
        </div>
      </div>
    </>
  )
}

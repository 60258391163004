import { useState, useEffect } from 'react'

import Button from 'components/Button'
import Field from 'components/Field'
import SkeletonText from 'components/Skeleton/SkeletonText'
import useSearch from 'hooks/useSearch'
import {
  getMarketingDetail,
  setMarketingUserInterest,
} from 'redux/common/actions'
import { useAppDispatch } from 'redux/hooks'

export default function Marketing() {
  const { ubid, rcid } = useSearch()
  const dispatch = useAppDispatch()
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [formData, setFormData] = useState({
    restaurant: '',
    note: '',
  })
  const [pageLoading, setPageLoading] = useState(true)
  const [data, setData] = useState({
    title: '',
    body: '',
  })

  useEffect(() => {
    // 查找已有的 <meta name="viewport"> 标签
    const viewportMeta = document.querySelector('meta[name="viewport"]')
    const newContent =
      'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, viewport-fit=cover'
    // 如果存在，则修改其 content 属性
    if (viewportMeta) {
      viewportMeta.setAttribute('content', newContent)
    } else {
      // 如果不存在，则创建一个新的 <meta> 标签并添加到 <head> 中
      const newViewportMeta = document.createElement('meta')
      newViewportMeta.name = 'viewport'
      newViewportMeta.content = newContent
      document.head.appendChild(newViewportMeta)
    }
    handleGetData()
  }, [])

  const handleGetData = async () => {
    try {
      setPageLoading(true)
      setErrorMsg('')
      const res = await dispatch(
        getMarketingDetail({
          ubid,
          rcid,
        }),
      )
      if (res) {
        setData({ title: res?.campaign?.title, body: res?.campaign?.body })
        setFormData({
          restaurant: res?.user_brand?.brand?.name || '',
          note: '',
        })
      }
    } catch (e: any) {
      setErrorMsg(e.message || 'Request error')
    } finally {
      setPageLoading(false)
    }
  }

  const handleFormDataChange = (type: string, value: string) => {
    setFormData({
      ...formData,
      [type]: value,
    })
    if (disabled) {
      setDisabled(false)
    }
  }

  const handleClick = async () => {
    try {
      setLoading(true)
      setErrorMsg('')
      setSuccessMsg('')
      const res = await dispatch(
        setMarketingUserInterest({
          ubid,
          rcid,
          note: formData.note,
        }),
      )
      setDisabled(true)
      if (res.message) {
        setSuccessMsg(res.message)
      }
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setErrorMsg(msg)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className='flex justify-center'>
      <div className='w-full flex flex-col sm:w-560px'>
        <div className='p-6 bg-white shadow-lg rounded-lg flex-1'>
          {errorMsg && (
            <p className='bg-red text-white text-base mb-4 p-4 rounded-sm text-center'>
              {errorMsg}
            </p>
          )}
          {successMsg && (
            <p className='bg-green text-white text-base mb-4 p-4 rounded-sm text-center'>
              {successMsg}
            </p>
          )}

          {pageLoading ? (
            <>
              <SkeletonText className='w-full h-9 mb-4' />
              <SkeletonText className='w-full h-40 mb-4' />
            </>
          ) : (
            <>
              <h1 className='text-3xl text-ink font-bold text-center mb-4'>
                {data.title}
              </h1>
              <p className='text-ink text-base mb-4 whitespace-pre-line'>
                {data.body}
              </p>
            </>
          )}
          <Field
            containerClassName='w-full'
            label='Restaurant'
            name='Restaurant'
            type='text'
            disabled
            placeholder='Input your restaurant'
            value={formData.restaurant}
            onChange={e => {
              handleFormDataChange('restaurant', e.target.value)
            }}
          />
          <Field
            containerClassName='mt-4'
            label='Note'
            name='note'
            type='text'
            placeholder='Input note'
            value={formData.note}
            onChange={e => {
              handleFormDataChange('note', e.target.value)
            }}
          />
        </div>
        <div className='mt-5 mb-10 px-5 sm:px-0'>
          <Button
            className='w-full'
            loading={loading}
            disabled={disabled || pageLoading}
            onClick={handleClick}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}
